module.exports = [
    {
        displayValue: '2',
        suit: 'clubs',
        strength: 2,
        imgPath: process.env.PUBLIC_URL + '/card-images/2_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '3',
        suit: 'clubs',
        strength: 3,
        imgPath: process.env.PUBLIC_URL + '/card-images/3_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '4',
        suit: 'clubs',
        strength: 4,
        imgPath: process.env.PUBLIC_URL + '/card-images/4_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '5',
        suit: 'clubs',
        strength: 5,
        imgPath: process.env.PUBLIC_URL + '/card-images/5_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '6',
        suit: 'clubs',
        strength: 6,
        imgPath: process.env.PUBLIC_URL + '/card-images/6_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '7',
        suit: 'clubs',
        strength: 7,
        imgPath: process.env.PUBLIC_URL + '/card-images/7_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '8',
        suit: 'clubs',
        strength: 8,
        imgPath: process.env.PUBLIC_URL + '/card-images/8_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '9',
        suit: 'clubs',
        strength: 9,
        imgPath: process.env.PUBLIC_URL + '/card-images/9_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '10',
        suit: 'clubs',
        strength: 10,
        imgPath: process.env.PUBLIC_URL + '/card-images/10_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        name: 'Jack',
        displayValue: 'J',
        suit: 'clubs',
        strength: 11,
        imgPath: process.env.PUBLIC_URL + '/card-images/jack_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        name: 'Queen',
        displayValue: 'Q',
        suit: 'clubs',
        strength: 12,
        imgPath: process.env.PUBLIC_URL + '/card-images/queen_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        name: 'King',
        displayValue: 'K',
        suit: 'clubs',
        strength: 13,
        imgPath: process.env.PUBLIC_URL + '/card-images/king_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: 'A',
        suit: 'clubs',
        strength: 14,
        imgPath: process.env.PUBLIC_URL + '/card-images/ace_of_clubs.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '2',
        suit: 'spades',
        strength: 2,
        imgPath: process.env.PUBLIC_URL + '/card-images/2_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '3',
        suit: 'spades',
        strength: 3,
        imgPath: process.env.PUBLIC_URL + '/card-images/3_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '4',
        suit: 'spades',
        strength: 4,
        imgPath: process.env.PUBLIC_URL + '/card-images/4_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '5',
        suit: 'spades',
        strength: 5,
        imgPath: process.env.PUBLIC_URL + '/card-images/5_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '6',
        suit: 'spades',
        strength: 6,
        imgPath: process.env.PUBLIC_URL + '/card-images/6_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '7',
        suit: 'spades',
        strength: 7,
        imgPath: process.env.PUBLIC_URL + '/card-images/7_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '8',
        suit: 'spades',
        strength: 8,
        imgPath: process.env.PUBLIC_URL + '/card-images/8_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '9',
        suit: 'spades',
        strength: 9,
        imgPath: process.env.PUBLIC_URL + '/card-images/9_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '10',
        suit: 'spades',
        strength: 10,
        imgPath: process.env.PUBLIC_URL + '/card-images/10_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        name: 'Jack',
        displayValue: 'J',
        suit: 'spades',
        strength: 11,
        imgPath: process.env.PUBLIC_URL + '/card-images/jack_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        name: 'Queen',
        displayValue: 'Q',
        suit: 'spades',
        strength: 12,
        imgPath: process.env.PUBLIC_URL + '/card-images/queen_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        name: 'King',
        displayValue: 'K',
        suit: 'spades',
        strength: 13,
        imgPath: process.env.PUBLIC_URL + '/card-images/king_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: 'A',
        suit: 'spades',
        strength: 14,
        imgPath: process.env.PUBLIC_URL + '/card-images/ace_of_spades.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '2',
        suit: 'hearts',
        strength: 2,
        imgPath: process.env.PUBLIC_URL + '/card-images/2_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '3',
        suit: 'hearts',
        strength: 3,
        imgPath: process.env.PUBLIC_URL + '/card-images/3_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '4',
        suit: 'hearts',
        strength: 4,
        imgPath: process.env.PUBLIC_URL + '/card-images/4_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '5',
        suit: 'hearts',
        strength: 5,
        imgPath: process.env.PUBLIC_URL + '/card-images/5_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '6',
        suit: 'hearts',
        strength: 6,
        imgPath: process.env.PUBLIC_URL + '/card-images/6_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '7',
        suit: 'hearts',
        strength: 7,
        imgPath: process.env.PUBLIC_URL + '/card-images/7_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '8',
        suit: 'hearts',
        strength: 8,
        imgPath: process.env.PUBLIC_URL + '/card-images/8_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '9',
        suit: 'hearts',
        strength: 9,
        imgPath: process.env.PUBLIC_URL + '/card-images/9_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '10',
        suit: 'hearts',
        strength: 10,
        imgPath: process.env.PUBLIC_URL + '/card-images/10_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        name: 'Jack',
        displayValue: 'J',
        suit: 'hearts',
        strength: 11,
        imgPath: process.env.PUBLIC_URL + '/card-images/jack_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        name: 'Queen',
        displayValue: 'Q',
        suit: 'hearts',
        strength: 12,
        imgPath: process.env.PUBLIC_URL + '/card-images/queen_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        name: 'King',
        displayValue: 'K',
        suit: 'hearts',
        strength: 13,
        imgPath: process.env.PUBLIC_URL + '/card-images/king_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: 'A',
        suit: 'hearts',
        strength: 14,
        imgPath: process.env.PUBLIC_URL + '/card-images/ace_of_hearts.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '2',
        suit: 'diamonds',
        strength: 2,
        imgPath: process.env.PUBLIC_URL + '/card-images/2_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '3',
        suit: 'diamonds',
        strength: 3,
        imgPath: process.env.PUBLIC_URL + '/card-images/3_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '4',
        suit: 'diamonds',
        strength: 4,
        imgPath: process.env.PUBLIC_URL + '/card-images/4_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '5',
        suit: 'diamonds',
        strength: 5,
        imgPath: process.env.PUBLIC_URL + '/card-images/5_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '6',
        suit: 'diamonds',
        strength: 6,
        imgPath: process.env.PUBLIC_URL + '/card-images/6_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '7',
        suit: 'diamonds',
        strength: 7,
        imgPath: process.env.PUBLIC_URL + '/card-images/7_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '8',
        suit: 'diamonds',
        strength: 8,
        imgPath: process.env.PUBLIC_URL + '/card-images/8_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '9',
        suit: 'diamonds',
        strength: 9,
        imgPath: process.env.PUBLIC_URL + '/card-images/9_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: '10',
        suit: 'diamonds',
        strength: 10,
        imgPath: process.env.PUBLIC_URL + '/card-images/10_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        name: 'Jack',
        displayValue: 'J',
        suit: 'diamonds',
        strength: 11,
        imgPath: process.env.PUBLIC_URL + '/card-images/jack_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        name: 'Queen',
        displayValue: 'Q',
        suit: 'diamonds',
        strength: 12,
        imgPath: process.env.PUBLIC_URL + '/card-images/queen_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        name: 'King',
        displayValue: 'K',
        suit: 'diamonds',
        strength: 13,
        imgPath: process.env.PUBLIC_URL + '/card-images/king_of_diamonds.svg',
        spotIsStillValid: true,
    },
    {
        displayValue: 'A',
        suit: 'diamonds',
        strength: 14,
        imgPath: process.env.PUBLIC_URL + '/card-images/ace_of_diamonds.svg',
        spotIsStillValid: true,
    },
];
